import {React, useState} from "react";
import './Control.scss';

const Control = ({sendUserMessage}) => {
  // the new message (when submitted)
  const [message, setMessage] = useState('');

  const submitOnEnter = (event) => {
    if(event.key === 'Enter') {
      handleSubmit();
    }
  }

  const handleSubmit = (e) => {
    //prevent the form from reloading
    e?.preventDefault();

   //contains only whitespace characters? return.
    if(!/\S/.test(message)) return;

    // send Message to Bot
    sendUserMessage(message);

    //clear field
    setMessage("");
  }

  return (
    <form className="control" onSubmit={handleSubmit}>
      <input type="text" value={message} placeholder="Stelle eine Frage..." onChange={e => setMessage(e.target.value)} onKeyDown={submitOnEnter} tabIndex={0}/>
      <button type="submit" title="Frage abschicken" tabIndex={0}>▶</button>
    </form>
  );
}

export default Control;